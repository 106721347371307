export default [
   {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         action: 'read',
         },
   },
   {
      path: '/login', // on first visit or unauth redirect
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: true,
      },
   },
]
