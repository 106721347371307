
export const $environments = ['local', 'debug', 'testing', 'staging', 'live']

export const $appConfig = {
  app: {
    name: 'Pelican',
    env: $environments[1],
  },
  api: {
    //url: 'http://pelican.test',
    url: 'https://api-staging.pelicantrivia.info',
  },
  layout: {
  },
}
