export default [
   {
      path: '/questions', // question manager
      name: 'questions',
      component: () => import('@/views/questions/QuestionsManager.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: true,
      },
   },
]
