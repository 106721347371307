<template>
  <div id="routerContainer">
     <router-view/>
  </div>
</template>

<script>
   import { inject } from 'vue'

   // Import config and localization files
   import { $appConfig } from '@/config/app.js'
   import { makeToast } from '@/api/toast.js'

   // Import hub utility from amplify
   import { Hub } from 'aws-amplify'

   // Global styles
   import '@/assets/css/global.css'

   // Example guarded console message to avoid internal messages being printed on live
   if($appConfig.app.env === 'debug') {
      console.log($appConfig);
   }

   export default {
      name: 'App',
      components: {
      },
      setup() {
         const $router = inject('$router')
         // Clear old listeners
         Hub.remove('auth')
         // Register anonymous auth event listener
         Hub.listen('auth', (data) => {
            // Get access to router inside this anonymous callback
            // Handle event
            switch(data.payload.event) {
               case 'signOut':
                  makeToast('Logout success', 'success')
                  // Redirect to entry view
                  $router.push({ path: '/login' })
                  break
            }
         })
      }
   }
</script>

<style scoped>

</style>
