
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

export const makeToast = (message, variant, callback, timeout) => {
   createToast(message, {
         type: variant,
         position: "top-center",
         onClose: callback,
         timeout: timeout,
         showIcon: true
      }
   )
}
