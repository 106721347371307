
import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory } from 'vue-router'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import questionsManager from './routes/questions'
import approvalsManager from './routes/approvals'
import { $appConfig } from '@/config/app.js'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
      scrollBehavior() {
         return { x: 0, y: 0 }
      },
      routes: [
         { path: '/', redirect: { name: 'login' } },
         ...dashboard,
         ...pages,
         ...questionsManager,
         ...approvalsManager,
      ],
})

// Check permissions before each route change
router.beforeEach((to, _, next) => {
   // Verify user is authenticated
   let isLoggedIn = false
   try {
      const user = Auth.currentAuthenticatedUser()
      if(user) isLoggedIn = true
   } catch(e) {
      console.log('error authenticating user before route change', e)
   }
   // Debug message
   if($appConfig.app.env === 'debug') {
      console.log(`user is authenticated? ${isLoggedIn}`);
   }
   // Auth guard redirect
   if (to.name !== 'login' && !isLoggedIn) {
      next({ name: 'login' })
   } else {
      next()// OK to change route
   }
})

export default router
