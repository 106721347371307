export default [
   {
      path: '/approvals', // approvals manager
      name: 'approvals',
      component: () => import('@/views/approvals/ApprovalsManager.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: true,
      },
   },
]
