
// Package imports
import Amplify, * as AmplifyModules from 'aws-amplify'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { createApp } from 'vue'
import { $awsConfig } from '@/config/aws.js'

// Fonts and styles
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Component imports
import App from './App.vue'
import router from './router/index.js'

// Configure AWS amplify
Amplify.configure($awsConfig)

// Create axios instance
const axiosInstance = axios
// Add request interceptor for auth header to axios instance
axiosInstance.interceptors.request.use(
   config => new Promise(resolve => {
      // Get token from amplify
      Auth.currentSession().then(res => {
         //console.log(config)
         const token = res.getIdToken()
         const jwt = token.getJwtToken()
         // Add authorization header
         if(jwt) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${jwt}`
            config.headers.Accept = 'application/json'
         }
         resolve(config)
      })
   }),
   error => Promise.reject(error),
)

const app = createApp(App) // init app
app.use(AmplifyModules) // register AWS amplify modules
app.use(router) // register our router instance
app.component('FontAwesomeIcon', FontAwesomeIcon) // make FA icon component globally available
app.provide('$axios', axiosInstance) // provide axios as global in entire app
app.provide('$router', router) // provide router as global in entire app
app.mount('#app') // mount the root view
